import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Resources from 'Resources';
import { useEffect } from 'react';

const Interest = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, []);
  
  return (
    <Row className='py-2 py-md-4 border border-warning'>
      <Col className='h1 pt-2 col-12 px-0 d-flex justify-content-center'>INTEREST</Col>
      {
        props.partial ?
          <Container className="pt-2 px-0" fluid>
            <Row className='col-12 mx-0 px-0'>
              {Array.from({ length: 6 }, (ele, index) => (
                <Col key={"i" + index} className='col-12 col-md-6 col-xl-4 py-4 px-0 px-md-4'>
                  <Row className="mx-0">
                    <Row className="mx-0 py-1 justify-content-center fs-5 fw-bold">
                      {"TITLE"}
                    </Row>
                    <Row className="mx-0">
                      <Image className='border rounded object-fit-cover w-100' height={200} />
                    </Row>
                    <Row className="mx-1 py-2">
                      {"TEXT"}
                    </Row>
                    <Row className="mx-0">
                      <Button className='fw-bold'>{"OPEN WEBPAGE"}</Button>
                    </Row>
                  </Row>
                </Col>
              ))}
            </Row>
            <Row>
              <Col className='d-flex justify-content-center p-3'>
                <Link to={"/interest"} >
                  <Button className="px-5 py-2 fs-4 fw-bold">{"CHECK ALL"}</Button>
                </Link>
              </Col>
            </Row>
          </Container>
          :
          <Container className="pt-2 px-0" fluid>
            <Row className='col-12 mx-0 px-0'>
              {Array.from({ length: 12 }, (ele, index) => (
                <Col key={"i" + index} className='col-12 col-md-6 col-xl-4 py-4 px-0 px-md-4'>
                  <Row className="mx-0">
                    <Row className="mx-0 py-1 justify-content-center fs-5 fw-bold">
                      {"TITLE"}
                    </Row>
                    <Row className="mx-0">
                      <Image className='border rounded object-fit-cover w-100' height={200} />
                    </Row>
                    <Row className="mx-1 py-3">
                      {Resources.lorem.substring(0,400)}
                    </Row>
                    <Row className="mx-0">
                      <Button className='fw-bold'>{"OPEN WEBPAGE"}</Button>
                    </Row>
                  </Row>
                </Col>
              ))}
            </Row>
          </Container>
      }
    </Row>
  );
}
export default Interest

import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Resources from 'Resources';

const Footer = () => {
  return (
    <Container className="mx-auto mb-5" fluid>
      <Row className="pt-5" style={{ backgroundColor: "#00808070" }}>
        <Col className="col-auto mx-auto mx-0 pb-5 px-4">
          <Row className="fw-bold h4 pb-1" >QUICK LINKS</Row>
          {
            Object.keys(Resources.pages).map(ele => {
              return (
                <Row key={'f_' + Resources.pages[ele].key}>
                  <Link reloadDocument to={Resources.pages[ele].link} className={"ps-0 pt-1"}>{Resources.pages[ele].text_s}</Link>
                </Row>
              )
            })
          }
        </Col>
        <Col className="col-auto mx-auto mx-0 pb-5 px-4">
          <Row className="fw-bold h4 pb-1" >CONTACT INFO.</Row>
          <Row className='fw-bold fs-5'>BISERKA</Row>
          <Row>Phone: +385 91 780 78 82</Row>
          <Row>E-Mail: biserka.zakinja@gmail.com</Row>
          <Row className='fw-bold fs-5 pt-1'>GABRIEL</Row>
          <Row>Phone: +385 91 300 56 21</Row>
          <Row>E-Mail: gabriel.zakinja@gmail.com</Row>
        </Col>
      </Row>
      <Row className='bg-info bg-gradient bg-opacity-25'>
        <Col className="py-4">
          <Row className="py-1">
            <Col className="col-auto mx-auto">
              © 2024 Appartments Zakinja
            </Col>
          </Row>
          <Row className="py-1">
            <Col className="col-auto mx-auto">
              Made by: <Link to={'http://www.inovigrad.com/'} target="_blank" className="px-1">iNovigrad</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
export default Footer
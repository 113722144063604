
import { Container, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

const Calendar = (inProps) => {
  //DEFAULT INPUT VALUES/PROPS
  const props = {
    days: inProps.days,
    className: {
      mainCont: 'px-0 border mx-auto ',
      headerCont: 'd-flex justify-content-center mx-auto py-2 px-1 px-sm-3 fs-3 fw-bold bg-info ',
      daysCont: 'd-flex justify-content-center mx-auto ',
      daysRow: 'flex-nowrap justify-content-center px-0 ',
      daysCol: 'd-flex justify-content-center align-content-center fw-bold border fs-4 py-1 ',
    },
    css: {
      mainCont: {},
      headerCont: {},
      daysCont: {backgroundColor: '#ff5c33'},
      daysContFill: {backgroundColor: '#1aff1a'},
      daysContEmpty: {backgroundColor: '#bfbfbf'},
      daysRow: {},
      daysCol: {
        width: "14.28%",
        maxWidth: "14.28%"
      },
      legendColorBox: {
        width: "20px",
        maxWidth: "20px",
        minWidth: "20px",
        height: "20px",
        maxHeight: "20px",
        minHeight: "20px",
      }
    },
    legend: inProps.legend
  }
  props.date = {
    year: inProps.date.year ? inProps.date.year : new Date().getFullYear(),
    month: inProps.date.month ? new Date(inProps.date.year, inProps.date.month).getMonth() : new Date().getMonth() + 1,
    monthName: inProps.date.monthName ? inProps.date.monthName : new Date(inProps.date.year, inProps.date.month).toLocaleString('default', { month: 'long' }),
    day: inProps.date.day ? new Date(inProps.date.year, inProps.date.month, inProps.date.day) : null,
  }

  let firstDayIndex = new Date(props.date.year, props.date.month - 1, 0).getDay();
  var countDay = 0;
  const totalWeeks = getWeekOfMonth();
  const days = daysInMonth();

  //RETURNS NUMBER (int) OF WEEKS OF SPECIFIED YEAR & MONTH
  function getWeekOfMonth() {
    const firstDay = new Date(props.date.year, props.date.month - 1, 0).getDay();
    const totalDays = new Date(props.date.year, props.date.month, 0).getDate();
    return Math.ceil((firstDay + totalDays) / 7);
  }

  //RETURNS ARRAY OF DAYS (int) OF SPECIFIED YEAR & MONTH
  function daysInMonth() {
    return Array.from(Array(new Date(props.date.year, props.date.month, 0).getDate()).keys(), (i) => (i + 1));
  }

  return (
    <Container className={props.className.mainCont} >
      <Row className={props.className.headerCont}>
        <Col className='col-8 text-nowrap d-flex d-sm-none justify-content-start'>
          {props.date.month + ". "+props.date.monthName}
        </Col>
        <Col className='col-4 d-none d-sm-flex justify-content-start'>
          {props.date.month + ". "}
        </Col>
        <Col className='col-4 d-none d-sm-flex justify-content-center'>
          {props.date.monthName}
        </Col>
        <Col className='col-4 d-flex justify-content-end'>
          {props.date.year}
        </Col>
      </Row>
      <Row className={props.className.daysCont}>
        {
          Array.from({ length: totalWeeks }, (
            (ele1, index1) => (
              <Row className={props.className.daysRow} key={"c_r" + index1}>
                {
                  Array.from({ length: 7 }, (
                    (ele2, index2) => {
                      return (
                        <Col className={props.className.daysCol}
                          style={{...props.css.daysCol, 
                            ...(((index1 == 0 && index2 < firstDayIndex) || countDay >= days.length) ?
                              props.css.daysContEmpty :
                              props.days.includes(days[countDay]) ? props.css.daysCont : props.css.daysContFill
                            )
                          }}

                          key={"c_c" + index1 + index2}>
                          {
                            (index1 == 0 ? (index2 >= firstDayIndex ? days[countDay++] : "") : days[countDay++])
                          }
                          
                        </Col>
                      );
                    }
                  ))
                }
              </Row>
            )
          ))
        }
      </Row>
      <Row className='d-flex justify-content-center align-content-center p-2'> 
        <Col className='col-12 col-sm-auto d-flex flex-nowarp justify-content-center align-content-center mx-sm-auto'>
          <Row>
            <Col className={props.className.daysCol + ' align-self-center m-1'} style={{...props.css.legendColorBox, ...props.css.daysContFill}}></Col>
            <Col className='fw-bold fs-4'>AVAILABLE</Col>
          </Row>
        </Col>
        <Col className='col-12 col-sm-auto d-flex flex-nowarp justify-content-center align-content-center mx-sm-auto'>
          <Row>
            <Col className={props.className.daysCol + ' align-self-center m-1'} style={{...props.css.legendColorBox, ...props.css.daysCont}}></Col>
            <Col className='fw-bold fs-4'>BOOKED</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
export default Calendar

import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from "react-bootstrap";
import Header from "Header";
import Footer from "Footer";
import Homepage from "Homepage";
import NotFound from "NotFound";
import Contact from "Contact";
import AppDetails from "AppDetails";
import Interest from "Interest";

function App() {
  return (
    <BrowserRouter>
      <Container className='px-3 col-auto col-xxl-10 col-xl-10 col-lg-auto' fluid>
        <Header />
        <Routes>
          <Route index element={<Homepage />} />
          <Route path="appDetails" element={<AppDetails />} />
          <Route path="contact" element={<Contact />} />
          <Route path="interest" element={<Interest />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Container>
    </BrowserRouter>
  );
}
export default App;

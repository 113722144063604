import { Col, Row, Button, Image, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

const Gallery = ({ imagesRef, showGallery, setShowGallery, showImageByID, setSelectedImageID }) => {
  const [images, setImages] = useState([]);
  const imagesThumbnailsRefs = useRef({});
  const [imageName, setImageName] = useState(null);
  const [imageIDs, setImageIDs] = useState([]);
  const setImageID = (imageID) => {
    setSelectedImageID(imageID);
    imagesThumbnailsRefs.current['imageThumbnail_'+imageID].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    const imageAlt = images.filter((ele) => ele.ID === imageID).map((ele) => {return ele.post_title})[0];
    setImageName(imageAlt ?? '');
  };

  const hideGallery = () => {
    setShowGallery(false);
  };

  //TRIGGER - ON CREATE
  useEffect(() => {
    if (imagesRef) {
      setImages(imagesRef);
      setImageIDs(imagesRef.map(img => img.ID));
    }
  }, [imagesRef]);

  //TRIGGER FROM PARENT COMPONENT -> WHEN SELECTING IMAGE TO OPEN
  useEffect(() => {
    if (showImageByID) {
      const imageAlt = images.filter((ele) => ele.ID === showImageByID).map((ele) => {return ele.post_title})[0];
      setImageName(imageAlt ?? '');
    }
  }, [showImageByID, images]);
  
  //FUNCTION TO SELECT NEXT / PREV IMAGE
  const selectNextPrevImage = (isNext) => {
    const currentIndex = imageIDs.indexOf(showImageByID);
    const nextIndex = (isNext ? (currentIndex + 1) : (currentIndex - 1 + imageIDs.length)) % imageIDs.length;
    setImageID(imageIDs[nextIndex]);
  };

  //STYLE - NEXT / PREV BUTTON
  const btnStyle = {
    width: "100%",
    height: "100%",
    fontSize: "70px",
    fontWeight: "bold",
    color: "#008080",
    backgroundColor: "transparent",
    background: "transparent",
    border: "none",
  }

  return (
    <Modal
      show={showGallery}
      onHide={() => hideGallery()}
      backdrop="static"
      keyboard={false}
      fullscreen={true}
      animation={false}
      
      centered
    >
      <Modal.Header style={{backgroundColor: "#E6E6FA"}} className='px-4 py-2 fs-4 border' closeButton>
        <Modal.Title>{imageName ?? ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor: "#F0F8FF"}} className='px-4 py-0' >
        <Row className='h-100'>
          <Col className='col-3 col-lg-2 position-absolute top-50 start-0 translate-middle-y h-100'>
            <Button style={btnStyle} onClick={() => {selectNextPrevImage(false)}}>{"<"}</Button>
          </Col>
          <Col id='col_galleryImages' className='col-0 d-flex h-100 justify-content-center align-items-center px-0 mx-0'>
            {
              images && images.length > 0 ?
                images.filter((ele) => ele.ID === showImageByID).map((ele, index) => (
                  <Row key={'g_i_'+ele.ID} className={"col-12 h-100 d-flex px-0 mx-0"}>
                    <Image src={ele.sizes.full.source_url} className='object-fit-contain w-100 h-100 px-0 mx-0 ' />
                  </Row>
                ))
                : <></>
            }
          </Col>
          <Col className='col-3 col-lg-2 position-absolute top-50 end-0 translate-middle-y me-2 h-100'>
            <Button style={btnStyle} onClick={() => {selectNextPrevImage(true)}}>{">"}</Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{backgroundColor: "#E6E6FA"}} className='px-0 mx-0 justify-content-center'>
        <Row className='d-flex w-100 px-2 px-sm-3 px-md-4'>
          <Col className="col-auto mx-auto d-flex flex-nowrap overflow-x-scroll hideScrollbar">
            {
              images && images.length > 0 ?
                images.map((ele, index) => (
                  <Col className={'mx-auto px-2'} key={'g_t_'+index}>
                    <Image
                      ref={(ref) => (imagesThumbnailsRefs.current['imageThumbnail_'+ele.ID] = ref)}
                      src={ele.sizes.thumbnail.source_url}
                      height={"80px"}
                      className={'rounded object-fit-cover ' + (showImageByID === ele.ID ? 'selectedThumbnail' : '')}
                      onClick={() => setImageID(ele.ID)} />
                  </Col>
                ))
                : <></>
            }
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default Gallery

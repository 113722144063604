import { Container, Row, Col, Image, Card, Placeholder } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import AboutNovigrad from 'AboutNovigrad';
import { GetPage, GetPost } from 'API';
import DOMPurify from 'dompurify';
import HomepageCard from 'HomepageCard';
import PlaceholderText from 'PlaceholderText';
import { Icons } from 'Icons';

const Homepage = () => {
  const [pageAPP1Content, setPageAPP1Content] = useState(null);
  const [pageAPP2Content, setPageAPP2Content] = useState(null);
  const [pageNovigradContent, setPageNovigradContent] = useState(null);
  const [pageInterestContent, setPageInterestContent] = useState(null);
  const sanitizer = DOMPurify.sanitize;

  useEffect(() => {
    async function loadData() {
      const fetchedPageAPP1 = await GetPost('appartments?_embed&slug=appperla');
      setPageAPP1Content(fetchedPageAPP1);
      const fetchedPageAPP2 = await GetPost('appartments?_embed&slug=appdavid');
      setPageAPP2Content(fetchedPageAPP2);
      const fetchedAboutNovigrad = await GetPage('aboutNovigrad');
      setPageNovigradContent(fetchedAboutNovigrad ?? null);
    }
    loadData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className='mx-0 w-100' fluid>
      <Row>
        {
          pageAPP1Content && pageAPP1Content[0]?._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.large?.source_url &&
          pageAPP2Content && pageAPP2Content[0]?._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.large?.source_url
           ?
            <>
              <Image src={pageAPP1Content[0]._embedded["wp:featuredmedia"][0].media_details.sizes.large.source_url} className='ps-0 pe-1 h-100 w-50' rounded/>
              <Image src={pageAPP2Content[0]._embedded["wp:featuredmedia"][0].media_details.sizes.large.source_url} className='ps-1 pe-0 h-100 w-50' rounded/>
            </>
            :
            <>
              <Placeholder as={Card.Img} height={"200px"} className='w-50 p-0 m-0' />
              <Placeholder as={Card.Img} height={"200px"} className='w-50 p-0 m-0' />
            </>
        }
      </Row>
      <Row className='mb-3 position-relative' style={{minHeight:"150px"}}>
        <Row className='mx-0 px-0 position-absolute top-0' style={{left: "-12vw", minWidth: "112vw"}}>
          <Icons get={"hr"} />
        </Row>
      </Row>
      <Row>
        {
          pageAPP1Content && pageAPP1Content[0]?.appartments?.appInfo ?
            <HomepageCard
              appDet={'appperla'}
              title={pageAPP1Content[0].title.rendered}
              description={pageAPP1Content[0].appartments.appInfo.description}
              image1={pageAPP1Content[0]?.appartments?.appInfo?.sideImage1?.sizes?.medium_large?.source_url ?? ""}
              image2={pageAPP1Content[0]?.appartments?.appInfo?.sideImage2?.sizes?.medium_large?.source_url ?? ""}
              includes={{"GENERAL INFO.": pageAPP1Content[0].appartments.appInfo.includes['GENERAL INFO.']}}
            /> :
            <Row className='px-0 mx-0'>
              <Col className='h1 pb-2 col-12 px-0 d-flex d-md-none justify-content-center'><Placeholder as="h1" className='w-50' /></Col>
              <Col className={'d-flex align-items-center col-12 col-md-6 col-lg-5 col-xl-4 px-0 pe-auto'}>
                <Col className='w-100 mx-0 h-100'>
                  <Placeholder as={Card.Img} height={"250px"} className='col-12 px-0 w-100' />
                  <Placeholder as={Card.Img} height={"250px"} className='col-12 px-0 w-100' />
                </Col>
              </Col>
              <Col className='d-flex col-12 col-md-6 col-lg-7 col-xl-8 p-2 pe-md-3 pt-4 pt-md-0'>
                <Row className='col-12 p-2 pt-0 m-0 align-content-center'>
                  <Col className='col-12 h1 d-none d-md-flex justify-content-center pb-3'><Placeholder as="h1" className='w-50' /></Col>
                  <Col className='col-12 '>
                    <PlaceholderText />
                  </Col>
                </Row>
              </Col>
            </Row>
        }
      </Row>
      <Row className='mb-3 position-relative' style={{minHeight:"150px"}}>
        <Row className='mx-0 px-0 position-absolute top-0' style={{left: "-12vw", minWidth: "112vw"}}>
          <Icons get={"hr"} />
        </Row>
      </Row>
      <Row>
        {
          pageAPP2Content && pageAPP2Content[0]?.appartments?.appInfo ?
            <HomepageCard
              appDet={'appdavid'}
              order={true}
              title={pageAPP2Content[0].title.rendered}
              description={pageAPP2Content[0].appartments.appInfo.description}
              image1={pageAPP2Content[0]?.appartments?.appInfo?.sideImage1?.sizes?.medium_large?.source_url ?? ""}
              image2={pageAPP2Content[0]?.appartments?.appInfo?.sideImage2?.sizes?.medium_large?.source_url ?? ""}
              includes={{"GENERAL INFO.": pageAPP2Content[0].appartments.appInfo.includes['GENERAL INFO.']}}
            /> : <></>
        }
      </Row>
      <Row className='mb-3 position-relative' style={{minHeight:"150px"}}>
        <Row className='mx-0 px-0 position-absolute top-0' style={{left: "-12vw", minWidth: "112vw"}}>
          <Icons get={"hr"} />
        </Row>
      </Row>
      {
        false ? 
          <Row>
            <Col className='h1 fw-bold text-center'>ABOUT NOVIGRAD</Col>
          {
            false ?
              <AboutNovigrad aboutNovigrad={pageNovigradContent} /> 
              :
              <HomepageCard
                  appDet={''}
                  order={true}
                  subTitle={"TITLE"}
                  description={"DESCRIPTION"}
                  image1={""}
                  
                />
          }

          {
            //ADD WHEN CREATED: <Interest partial={true}/>
          }
        </Row>
        :
        <></>
      }
      
    </Container>
  );
}
export default Homepage

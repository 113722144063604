import { Placeholder } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

const PlaceholderText = () => {
    return (
        <>
            <Placeholder as="p" className='col-2 me-2' /><Placeholder as="p" className='col-6 me-2' /><Placeholder as="p" className='col-2' />
            <Placeholder as="p" className='col-4 me-2' /><Placeholder as="p" className='col-3' />
            <Placeholder as="p" className='col-4 me-2' /><Placeholder as="p" className='col-3 me-2' /><Placeholder as="p" className='col-2' />
            <Placeholder as="p" className='col-4 me-2' /><Placeholder as="p" className='col-2' />
            <Placeholder as="p" className='col-2 me-2' /><Placeholder as="p" className='col-4 me-2' /><Placeholder as="p" className='col-4' />
            <Placeholder as="p" className='col-5 me-2' /><Placeholder as="p" className='col-6' />
            <Placeholder as="p" className='col-3 me-2' /><Placeholder as="p" className='col-3 me-2' /><Placeholder as="p" className='col-4' />
            <Placeholder as="p" className='col-2 me-2' /><Placeholder as="p" className='col-6 me-2' /><Placeholder as="p" className='col-2' />

        </>
    );
}
export default PlaceholderText
import { Container, Row, Col, Image, Button, Card, Placeholder } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Resources from 'Resources';
import { GetPost } from 'API';
import { Icons } from 'Icons';
import { Link } from 'react-router-dom';

const Contact = () => {
  const [pageAPP1Content, setPageAPP1Content] = useState(null);
  const [pageAPP2Content, setPageAPP2Content] = useState(null);
  const [showContact, setShowContact] = useState(true); //UPDATE THIS IN FUTURE

  useEffect(() => {
    async function loadData() {
      const fetchedPageAPP1 = await GetPost('appartments?_embed&slug=appperla');
      setPageAPP1Content(fetchedPageAPP1);
      const fetchedPageAPP2 = await GetPost('appartments?_embed&slug=appdavid');
      setPageAPP2Content(fetchedPageAPP2);
    }
    loadData();
    window.scrollTo(0, 0);
  }, []);

  const toggleButton = () => {
    setShowContact(!showContact);
  };

  return (
    <Container className='mx-0 w-100' fluid>
      <Row className='pb-2'>
        {
          pageAPP1Content && pageAPP1Content[0]?._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.large?.source_url &&
            pageAPP2Content && pageAPP2Content[0]?._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.large?.source_url
            ?
            <>
              <Image src={pageAPP1Content[0]._embedded["wp:featuredmedia"][0].media_details.sizes.large.source_url} className='ps-0 pe-1 h-100 w-50' rounded />
              <Image src={pageAPP2Content[0]._embedded["wp:featuredmedia"][0].media_details.sizes.large.source_url} className='ps-1 pe-0 h-100 w-50' rounded />
            </>
            :
            <>
              <Placeholder as={Card.Img} height={"200px"} className='w-50 p-0 m-0' />
              <Placeholder as={Card.Img} height={"200px"} className='w-50 p-0 m-0' />
            </>
        }
      </Row>
      <Row className='mb-2 position-relative' style={{ minHeight: "150px" }}>
        <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
          <Icons get={"hr"} />
        </Row>
      </Row>
      <Row className='h1 justify-content-center my-1 py-3 col-12 fw-bold'>CONTACT US</Row>
      <Row>
        {
          showContact ?
            <Container className='py-3 fs-5'>
              <Row className='mx-0'>
                <Col className='col-12 mx-0 px-0'>
                  <Row className='justify-content-center text-center mx-0 pb-5'>{"Feel free to reach out to us if you have any question or dubt. We are also available via WhatsApp and Viber. Contact information provided below."}</Row>
                  <Row className='mx-0 px-0'>
                    <Col className='col-12 col-sm-10 col-md-5 mb-4 px-0 mb-5 mx-auto justify-content-center boxBorderShadow'>
                      <Row className='m-0 py-4 h4 justify-content-center fw-bold cellBorders topBordersRounded'>GABRIEL</Row>
                      <Row className='align-items-center justify-content-center mx-0 mx-sm-0 py-1 px-0 cellBorders'>
                        <Row className='col-auto mx-0 align-items-center'>
                          <Col className='col-auto ps-0 pe-2 text-end'><Icons get={"phone"} /></Col>
                          <Col className='col-auto px-0 text-start'>Phone:</Col>
                        </Row>
                        <Col className='col-auto px-1 text-start'>+385 91 300 56 21</Col>
                      </Row>
                      <Row className='align-items-center justify-content-center mx-0 mx-sm-0 py-1 px-0 cellBorders bottomBordersRounded'>
                        <Row className='col-auto mx-0 align-items-center'>
                          <Col className='col-auto ps-0 pe-2 text-end'><Icons get={"email"} /></Col>
                          <Col className='col-auto px-0 text-start'>E-Mail:</Col>
                        </Row>
                        <Col className='col-auto px-1 text-start'>gabriel.zakinja@gmail.com</Col>
                      </Row>
                    </Col>
                    <Col className='col-12 col-sm-10 col-md-5 mb-4 px-0 mb-5 mx-auto justify-content-center boxBorderShadow'>
                      <Row className='m-0 py-4 h4 justify-content-center fw-bold cellBorders topBordersRounded'>BISERKA</Row>
                      <Row className='align-items-center justify-content-center mx-0 mx-sm-0 py-1 px-0 cellBorders'>
                        <Row className='col-auto mx-0 align-items-center'>
                          <Col className='col-auto ps-0 pe-2 text-end'><Icons get={"phone"} /></Col>
                          <Col className='col-auto px-0 text-start'>Phone:</Col>
                        </Row>
                        <Col className='col-auto px-1 text-start'>+385 91 780 78 82</Col>
                      </Row>
                      <Row className='align-items-center justify-content-center mx-0 mx-sm-0 py-1 px-0 cellBorders bottomBordersRounded'>
                        <Row className='col-auto mx-0 align-items-center'>
                          <Col className='col-auto ps-0 pe-2 text-end'><Icons get={"email"} /></Col>
                          <Col className='col-auto px-0 text-start'>E-Mail:</Col>
                        </Row>
                        <Col className='col-auto px-1 text-start'>biserka.zakinja@gmail.com</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Row className='mb-2 position-relative' style={{ minHeight: "150px" }}>
                  <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
                    <Icons get={"hr"} />
                  </Row>
                </Row>
                <Col>
                  <Row className='mx-0 pb-3 h3 justify-content-center fw-bold'>OUR LOCATION</Row>
                  {
                    pageAPP2Content && pageAPP2Content[0]?.appartments?.appInfo?.mapsImage?.sizes?.full?.source_url ?
                      <Image src={pageAPP2Content[0].appartments.appInfo.mapsImage.sizes.full.source_url} className='w-100 object-fit-cover' height={"400px"} rounded />
                      :
                      <Placeholder as={Card.Img} height={"400px"} className='w-100' />
                  }
                </Col>
                <Row className='text-center pt-5 pb-3 mx-0'>
                  <Col>
                    <Link to={((pageAPP2Content && pageAPP2Content[0]?.appartments?.appInfo?.mapsLocation) ? pageAPP2Content[0].appartments.appInfo.mapsLocation : '')} target="_blank">
                      <Button className="px-3 px-sm-5 py-4 fs-5 btn-success fw-bold btnBorderShadow">CHECK ON GOOGLE MAPS</Button>
                    </Link>
                  </Col>
                </Row>
              </Row>
              <Row className='mb-2 position-relative' style={{ minHeight: "150px" }}>
                <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
                  <Icons get={"hr"} />
                </Row>
              </Row>
            </Container>
            :
            <Container className='py-3 fs-5 border border-primary border-5'>
              <Row className='p-3'>
                {"TERMS AND CONDITIONS: " + Resources.lorem + Resources.lorem + Resources.lorem}
              </Row>
              <Row className='p-5'>
                <Col className='d-flex justify-content-center'>
                  <Button className='py-3 px-5 fw-bold fs-4' onClick={toggleButton}>ACCEPT</Button>
                </Col>
              </Row>
            </Container>
        }
      </Row>
    </Container>
  );
}
export default Contact
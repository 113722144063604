import { Alert, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

const NotFound = () => {
  return (
    <Container fluid>
        <Row className='py-5 text-center'>
          <Alert show={true} variant="danger h1" >
            <Alert.Heading><h1>ERROR 404</h1><h1>PAGE NOT FOUND</h1></Alert.Heading>
          </Alert>
        </Row>
    </Container>
  );
}
export default NotFound

import { Row, Col, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AppTableDetails from 'AppTableDetails';

//ADD INPUT FOR DATA
const HomepageCard = (props) => {
  return (
    props ?
      <Row className='px-0 mx-0 d-flex align-items-center'>
        {
          props.title ? <Col className='h1 pb-2 col-12 px-0 d-flex d-lg-none justify-content-center fw-bold'>{props.title}</Col> : <></>
        }
        {
          props.subTitle ? <Col className='h3 py-3 col-12 px-0 d-flex d-lg-none justify-content-center fw-bold'>{props.subTitle}</Col> : <></>
        }
        <Col className={'d-flex align-items-center col-12 col-md-5 col-lg-6 col-xl-6 px-0 pe-auto pb-4' + (props.order ? ' order-md-2' : ' ')}>
          <Col className='w-100 mx-0 h-100'>
            <Image src={props.image1} className='col-12 px-0 my-2 img-fluid w-100 rounded' />
            <Image src={props.image2} className='col-12 px-0 my-2 img-fluid w-100 rounded' />
          </Col>
        </Col>
        <Col className={'d-flex col-12 col-md-7 col-lg-6 col-xl-6 p-2 pe-md-3 pt-0 ' + (props.order ? ' order-md-1' : ' ')}>
          <Row className='col-12 p-2 pt-0 m-0 align-content-center'>
            {
              props.title ? <Row className='col-12 h1 d-none d-lg-flex justify-content-center pb-3 fw-bold'>{props.title}</Row> : <></>
            }
            {
              props.subTitle ? <Row className='col-12 h3 d-none d-lg-flex justify-content-center py-3 fw-bold'>{props.subTitle}</Row> : <></>
            }
            <Row className='col-12 px-0 px-md-2 mx-auto fs-5'>{props.description}</Row>
            <Row className='d-none d-lg-flex col-12 mx-auto pe-0'>
              <Row className='d-flex justify-content-evenly m-0 px-0 ps-md-2 pt-4 pb-2'>
                {
                  props.includes ?
                    Object.keys(props.includes).map((ele, index) => (
                      <AppTableDetails key={'atd' + index} title={ele} details={JSON.stringify(props.includes[ele])} css="col-10 col-sm-8 col-md-6 col-lg-10 px-auto py-2" />
                    ))
                    : <></>
                }
              </Row>
            </Row>
          </Row>
        </Col>
        <Col className={"col-12 mx-0 d-flex d-lg-none" + (props.order ? ' order-md-3' : ' ')}>
          <Row className='col-12 m-0 px-0 ps-md-2 justify-content-evenly py-2'>
            {
              props.includes ?
                Object.keys(props.includes).map((ele, index) => (
                  <AppTableDetails key={'atd' + index} title={ele} details={JSON.stringify(props.includes[ele])} css="col-11 col-sm-8 col-md-6 col-lg-5 py-2" />
                ))
                : <></>
            }
          </Row>
        </Col>
        {
          props.appDet ?
            <Col className={'col-12 py-2 mx-0' + (props.order ? ' order-md-4' : ' ')}>
              <Col className='d-flex justify-content-center pt-2'>
                <Link to={'appDetails?details=' + props.appDet}>
                  <Button className="px-5 py-4 fs-5 btn-success fw-bold btnBorderShadow">{"CHECK DETAILS"}</Button>
                </Link>
              </Col>
            </Col> : <></>
        }

      </Row>
      :
      <></>
  );
}
export default HomepageCard

import { Container, Row, Col, Image, Card, Placeholder, Carousel, CarouselItem, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import NotFound from 'NotFound';
import AppTableDetails from 'AppTableDetails';
import Calendar from 'Calendar';
import { useEffect } from 'react';
import { GetPost } from 'API';
import Gallery from 'Gallery';
import { Icons } from 'Icons';

const AppDetails = () => {
  //LOCTION PARAMS
  const [searchParams] = useSearchParams();
  const appID = searchParams.get("details");

  //API DATA FETCH
  const [appData, setAppData] = useState(null);
  const [appTitle, setAppTitle] = useState(null);
  const [appFeaturedImage, setAppFeaturedImage] = useState(null);
  const [appSideImages, setAppSideImages] = useState([]);
  const [appDescription, setAppDescription] = useState(null);
  const [appIncludes, setAppIncludes] = useState([]);
  const [appDates, setAppDates] = useState([]);
  const [appGallery, setAppGallery] = useState([]);
  const [appMapsLocation, setAppMapsLocation] = useState(null);
  const [appMapsImage, setAppMapsImage] = useState(null);

  const [showGallery, setShowGallery] = useState(false);
  const [selectedImageID, setSelectedImageID] = useState(0);

  //FETCH PAGE DATA
  useEffect(() => {
    async function loadData() {
      const fetchedPage = await GetPost('appartments?_embed&slug=' + appID);
      setAppData(fetchedPage);
    }
    loadData();
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, []);

  //SET PAGE DATA WHEN PAGE IS FETCHED
  useEffect(() => {
    if (appData && appData[0]?.appartments?.appInfo) {
      setAppTitle(appData[0]?.title?.rendered);
      setAppFeaturedImage(appData[0]?._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url);
      setAppSideImages([appData[0]?.appartments?.appInfo?.sideImage1?.sizes?.medium_large?.source_url, appData[0]?.appartments?.appInfo?.sideImage2?.sizes?.medium_large?.source_url]);
      setAppDescription(appData[0]?.appartments?.appInfo?.description);
      setAppIncludes(appData[0]?.appartments?.appInfo?.includes ? appData[0].appartments.appInfo.includes : []);
      setAppDates(appData[0]?.appartments?.appInfo?.dates ? appData[0].appartments.appInfo.dates : []);
      setAppGallery(appData[0]?.appartments?.appInfo?.gallery);
      setAppMapsLocation(appData[0]?.appartments?.appInfo?.mapsLocation);
      setAppMapsImage(appData[0]?.appartments?.appInfo?.mapsImage?.sizes?.full?.source_url);
    }
  }, [appData]);

  //CREATE ARRAY FOR DATES TO DISPLAY 2 MONTHS
  /* FOR FUTURE REFERENCE
  useEffect(() => {
    if (appDates) {
      var tempObjectArr = [];
      var tempObject = [];
      var count = 0;
      Object.keys(appDates).map((year, index) => {
        Object.keys(appDates[year]).map((month, index2) => {
          if (count % 2 == 0) {
            tempObject.push({ [year]: { [month]: appDates[year][month] } });
          } else {
            tempObject.push({ [year]: { [month]: appDates[year][month] } });
            tempObjectArr.push(tempObject);
            tempObject = [];
          }
          count++;
        })
      });
      if (count % 2 != 0) { tempObjectArr.push(tempObject); }
      setAppDates2(tempObjectArr);
    }
  }, [appDates]);
  */
  if (appID === 'appperla' || appID === 'appdavid') {
    return (
      <Container className='mx-0 w-100' fluid>
        <Row>
          {
            appFeaturedImage ?
              <>
                <Image src={appFeaturedImage} className='px-0 d-sm-none rounded object-fit-contain w-100' />
                <Image src={appFeaturedImage} className='px-0 d-none d-sm-inline rounded object-fit-cover w-100' height={"500px"} />
              </>
              :
              <Placeholder as={Card.Img} height={"500px"} className='w-100 mx-0 px-0 rounded' />
          }
        </Row>
        <Row className='mb-3 position-relative' style={{ minHeight: "150px" }}>
          <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
            <Icons get={"hr"} />
          </Row>
        </Row>
        <Row className='d-flex align-items-start d-flex align-items-center'>
          <Col className='h1 pb-2 col-12 px-0 d-flex d-md-none justify-content-center fw-bold'>{appTitle}</Col>
          <Col className='d-flex align-items-center col-12 col-md-5 col-lg-4 px-0 pe-auto pb-4'>
            <Col className='w-100 mx-0 h-100'>
              <Image src={appSideImages[0]} className='col-12 px-0 my-2 img-fluid w-100 rounded' />
              <Image src={appSideImages[1]} className='col-12 px-0 my-2 img-fluid w-100 rounded' />
            </Col>
          </Col>
          <Col className='col-12 col-md-7 col-lg-8 p-2 pe-md-3 pt-0'>
            <Row className='col-12 p-2 pt-0 m-0'>
              <Col className='col-12 h1 d-none d-md-flex justify-content-center pb-4 fw-bold'>{appTitle}</Col>
              <Col className='col-12 px-0 px-md-2 mx-auto fs-5'>{appDescription}</Col>
            </Row>
          </Col>
          <Col className="col-12 mx-0">
            <Row className='col-12 m-0 px-0 ps-md-2 justify-content-evenly py-2'>
              {
                appIncludes ?
                  Object.keys(appIncludes).map((ele, index) => (
                    <AppTableDetails key={'atd' + index} title={ele} details={JSON.stringify(appIncludes[ele])} css="col-11 col-sm-8 col-md-6 col-lg-5 pt-2 pb-4" />
                  ))
                  : <></>
              }
            </Row>
          </Col>
        </Row>
        <Row className='mb-3 mt-0 pt-0 position-relative' style={{ minHeight: "150px" }}>
          <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
            <Icons get={"hr"} />
          </Row>
        </Row>
        <Row>
          <Row className='mx-0'>
            <Col className='h2 fw-bold d-flex justify-content-center pb-3'>AVAILABILITY</Col>
          </Row>
          <Row className='mx-0 px-0'>
            {
              appDates && Object.keys(appDates).length > 0 ? 
              <Carousel className='px-5 pb-5' data-bs-theme="dark" interval={null}>
                {
                  Object.keys(appDates).map((year, index) => (
                    Object.keys(appDates[year]).map((month, index2) => (
                      <CarouselItem key={"d_" + index + "_" + index2} className='px-0 px-sm-3 px-md-4 px-lg-5'>
                        <Calendar date={{ year: year, month: month, day: 0 }} days={appDates[year][month]} />
                      </CarouselItem>
                    ))
                  ))
                }
              </Carousel>
              : 
              <Col className='col-auto mx-auto text-center fw-bold fs-2 headerBTNBorderShadow nav-link py-5 px-5'>CALENDAR NOT AVAILABLE AT THE MOMENT</Col>
            }
            
          </Row>
        </Row>
        <Row className='mb-3 position-relative' style={{ minHeight: "150px" }}>
          <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
            <Icons get={"hr"} />
          </Row>
        </Row>
        <Row>
          <Col className='col-12 h2 fw-bold d-none d-md-flex justify-content-center pb-3'>GALLERY</Col>
          <Row className='col-12 mx-0 px-0'>
            {
              appGallery && appGallery.length > 0 ?
                appGallery.map((ele, index) => (
                  <Col key={"i" + index} className='col-12 col-md-6 col-xl-4 py-3 px-0 px-md-3'>
                    <Col>
                      <Image
                        src={ele.sizes.medium_large.source_url}
                        height={"250px"}
                        className='rounded object-fit-cover w-100'
                        onClick={() => {
                          setSelectedImageID(ele.ID);
                          setShowGallery(true);
                        }}
                      />
                    </Col>
                  </Col>
                ))
                :
                Array.from({ length: 9 }, (ele, index) => (
                  <Col key={"i_p_" + index} className='col-12 col-md-6 col-xl-4 py-4 px-0 px-md-4'>
                    <Row className="mx-0">
                      <Placeholder as={Card.Img} height={"200px"} className='w-100 mx-0 px-0 rounded' />
                    </Row>
                  </Col>
                ))
            }
          </Row>
        </Row>
        <Row>
          <Gallery
            imagesRef={appGallery}
            showGallery={showGallery}
            setShowGallery={setShowGallery}
            showImageByID={selectedImageID}
            setSelectedImageID={setSelectedImageID} />
        </Row>
        <Row className='mb-3 position-relative' style={{ minHeight: "150px" }}>
          <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
            <Icons get={"hr"} />
          </Row>
        </Row>
        <Row>
          <Row className='col-12 d-flex justify-content-center h2 fw-bold mx-0 pb-3'>LOCATION</Row>
          <Row className='mx-0 px-0 pb-2'>
            <Image src={appMapsImage ?? ''} className='px-0 rounded object-fit-cover w-100' height={"400px"} />
          </Row>
          <Row className='text-center pt-4 pb-3 mx-0'>
            <Col>
              <Link to={(appMapsLocation ?? '')} target="_blank">
                <Button className="px-3 px-sm-5 py-4 fs-5 btn-success fw-bold btnBorderShadow">CHECK ON GOOGLE MAPS</Button>
              </Link>
            </Col>
          </Row>
        </Row>
        <Row className='mb-3 position-relative' style={{ minHeight: "150px" }}>
          <Row className='mx-0 px-0 position-absolute top-0' style={{ left: "-12vw", minWidth: "112vw" }}>
            <Icons get={"hr"} />
          </Row>
        </Row>
      </Container>
    );
  }
  else {
    return (
      <NotFound />
    );
  }
}
export default AppDetails

import axios from "axios";
import Resources from "Resources";

//Fetch Page - Define page slug in input param
export const GetPage = async (slug) => {
  let result = null;
  try {
    await axios.get(Resources.WP_pageURL + slug).then((response) => {result = response.data;});
  } catch (err) {}
  
  return result;
}

//Fetch Post - Define post slug in input param
export const GetPost = async (postName) => {
  let result = null;
  try {
    await axios.get(Resources.WP_URL + postName).then((response) => {result = response.data;});
  } catch (err) {}
  
  return result;
}
import React from "react";
import { Image } from "react-bootstrap";
import ImagePhone from './images/IconPhone.webp';
import ImageEmail from './images/IconEmail.webp';
import ImageLocation from './images/IconLocation.webp';

export const Icons = (props) => {
  const iconsDim = "24px";

  //RETURNS ICON - HOME
  if (props && props.get == "home") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} fill="currentColor" viewBox="0 0 64 64">
        <defs>
          <linearGradient id="gradient1" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#00ceff" />
            <stop offset="10%" stopColor="#00e7a4" />
            <stop offset="20%" stopColor="#00ff4c" />
            <stop offset="60%" stopColor="#00ff4c" />
          </linearGradient>
          <linearGradient id="gradient2" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#00ccff" />
            <stop offset="60%" stopColor="#30a4ff" />
            <stop offset="90%" stopColor="#2c5eff" />
            <stop offset="100%" stopColor="#0000ff" />
          </linearGradient>
        </defs>
        <path d="M0 0L0 64L64 64L64 0 Z"
          stroke="url(#gradient1)" strokeWidth="2" strokeLinecap="round" fill="url(#gradient1)" />
        <path d="M32 50Q16 44 0 50L0 64L64 64L64 50Q48 56 32 50 Z"
          stroke="url(#gradient2)" strokeWidth="2" strokeLinecap="round" fill="url(#gradient2)" />
        <path d="M4 30L32 5L60 30
          M12 23L12 58L52 58L52 23
          M50 20L50 6L47 6L47 15"
          stroke="black" strokeWidth="6" strokeLinecap="round" fill="none" />
      </svg>
    );
  }

  //RETURNS ICON - CHECKMARK TRUE
  if (props && props.get == "true") {
    return (
      <span style={{ fontSize: iconsDim }}>✅</span>
    );
  }

  //RETURNS ICON - X FALSE
  if (props && props.get == "false") {
    return (
      <span style={{ fontSize: iconsDim }}>❌</span>
    );
  }

  //RETURNS ICON - X FALSE
  if (props && props.get == "phone") {
    return (
      <Image src={ImagePhone} className='' style={{ maxWidth: "38px" }} />
    );
  }

  //RETURNS ICON - X FALSE
  if (props && props.get == "email") {
    return (
      <Image src={ImageEmail} className='' style={{ maxWidth: "38px" }} />
    );
  }

  //RETURNS ICON - X FALSE
  if (props && props.get == "location") {
    return (
      <Image src={ImageLocation} className='' style={{ maxWidth: "38px" }} />
    );
  }

  if (props && props.get == "xxx") {
    return (
      <hr className='my-0 py-0 rounded' style={{ height: "40px", border: "0", boxShadow: "0 40px 40px -40px #00ffff inset" }}></hr>
    );
  }

  if (props && props.get == "hr") {
    return (
      <svg className='px-0 py-0 my-0' width="100%" height="150px" viewBox="0 0 6500 250" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice" overflow="auto" shapeRendering="auto">
        <defs>
          <pattern id="wavePattern" patternUnits="userSpaceOnUse" width="166" height="100%">
            <path d="M 0,250 L 0,85 Q 95,50 90,85 t 90,-6 v250 z" fill="url(#wave_gradient)" />
          </pattern>
          <pattern id="wavePattern2" patternUnits="userSpaceOnUse" width="166" height="100%">
            <path d="M 0,250 L 0,93 Q 90,55 83,93 t 83,0 v250 z" fill="url(#wave_gradient2)" />
          </pattern>
          <linearGradient id="wave_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#29B6F6" />
            <stop offset="55%" stopColor="#29B6F6ff" style={{ stopOpacity: 0 }} />
          </linearGradient>
          <linearGradient id="wave_gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#23b3f6" />
            <stop offset="55%" stopColor="#23b3f6ff" style={{ stopOpacity: 0 }} />
          </linearGradient>
        </defs>

        <rect x="0" y="-20" width="100%" height="250" fill="url(#wavePattern2)" />
        <rect x="0" y="0" width="100%" height="250" fill="url(#wavePattern)" opacity="0.7" />
      </svg>
    );
  }
}
